*,
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.swiper-container {
  width: 480px;
}
input:focus {
  outline: none;
}

@media screen and (min-width: 640px) {
  .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}
input {
  padding: 0px 10px;
  box-sizing: border-box;
}
::placeholder {
  font-weight: 600;
  font-size: 16px;
  color: #b4b4b4;
}
@media (max-width: 375px) {
  .text {
    font-size: 29px !important;
  }
}

@media (max-width: 375px) {
  .text-title {
    font-size: 13px !important;
  }
}

@media (max-width: 375px) {
  input:focus {
    border: 1px solid red !important;
  }
}

@media (max-width: 435px) {
  .swiper-arrow {
    display: none !important;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #dbdbdd;
  border-radius: 500px;
  border: 5px solid #cbbdbd;
}

::-webkit-scrollbar-thumb:hover {
  background: #ccc4c4;
}
.shadowImage {
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.1));
}
@media (max-width: 600px) {
  .button-container {
    width: 50%;
  }
}
@media (max-width: 400px) {
  .button-container {
    width: 100% !important;
  }
}

@media (max-width: 800px) {
  .card-title {
    margin-left: -22% !important;
  }
}

@media (max-width: 800px) {
  .buttonContainer {
    margin-left: -40% !important;
  }
}

@media (max-width: 400px) {
  .text-title {
    font-size: 18px;
  }
}

@media (max-width: 400px) {
  .card-arrow {
    right: 68px !important;
  }
}
@media (max-width: 1440px) {
  .card-titles {
    font-size: 24px !important;
  }
}

.d-none {
  display: none;
}
