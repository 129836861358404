.time-picker-container {
  display: flex;
  align-items: center;
  margin: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.time-picker-dropdown {
  margin-left: 10px;
}
.combined-input {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.combined-input input,
.combined-input select {
  padding: 5px;
  border: 1px solid #ccc;
  text-align: center;
  margin: 0 5px;
}

.time-separator {
  font-size: 20px;
  margin: 0 5px;
}

label {
  margin-bottom: 5px;
}
.time-picker-time__menu {
  max-height: 150px;
  overflow-y: auto;
}
